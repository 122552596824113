// frontend/src/pages/AdminDashboard.tsx

import React, { useEffect, useState, useContext } from 'react';
import axios from '../axiosConfig';
import { AuthContext } from '../context/AuthContext';

interface User {
    id: number;
    username: string;
    role: string;
}

const AdminDashboard: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const { authToken, logout } = useContext(AuthContext);

    useEffect(() => {
        axios
            .get('/api/users', {
                headers: { Authorization: `Bearer ${authToken}` },
            })
            .then((response) => {
                setUsers(response.data);
            })
            .catch((error) => {
                alert('Failed to fetch users');
                console.error(error);
            });
    }, [authToken]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Admin Dashboard</h2>
            <button onClick={logout}>Logout</button>
            <table border={1} style={{ margin: '20px auto' }}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user: User) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminDashboard;
