// frontend/src/components/PrivateRoute.tsx

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

interface PrivateRouteProps {
    children: JSX.Element;
    role?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, role }) => {
    const { authToken, userRole } = useContext(AuthContext);

    if (!authToken) {
        return <Navigate to="/" />;
    }

    if (
        role &&
        userRole !== role &&
        !(role === 'user' && userRole === 'admin')
    ) {
        // Allow administrators to access user routes
        return <Navigate to="/" />;
    }

    return children;
};

export default PrivateRoute;
