// frontend/src/axiosConfig.ts

import axios from 'axios';

const instance = axios.create({
    // uncomment line below only for local development. DO NOT PUSH it into PRODUCTION!
    //baseURL: 'http://localhost:5000',
    baseURL: 'https://testmagazie.folclorbanat.ro/',
});

export default instance;
