// frontend/src/pages/UserDashboard.tsx

import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

const UserDashboard: React.FC = () => {
    const { logout } = useContext(AuthContext);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Welcome to the User Dashboard</h2>
            <button onClick={logout}>Logout</button>
            {/* Add user-specific content here */}
        </div>
    );
};

export default UserDashboard;
