// frontend/src/context/AuthContext.tsx

import React, { createContext, useState } from 'react';

interface AuthContextProps {
    authToken: string | null;
    userRole: string | null;
    setTokens: (data: { token: string; role: string }) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
    authToken: null,
    userRole: null,
    setTokens: () => { },
    logout: () => { },
});

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const existingToken = localStorage.getItem('token');
    const existingRole = localStorage.getItem('role');
    const [authToken, setAuthToken] = useState<string | null>(existingToken);
    const [userRole, setUserRole] = useState<string | null>(existingRole);

    const setTokens = (data: { token: string; role: string }) => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('role', data.role);
        setAuthToken(data.token);
        setUserRole(data.role);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        setAuthToken(null);
        setUserRole(null);
    };

    return (
        <AuthContext.Provider value={{ authToken, userRole, setTokens, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
