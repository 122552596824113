// frontend/src/components/Login.tsx

import React, { useState, useContext } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { setTokens } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = () => {
        axios
            .post('/api/auth/login', { username, password })
            .then((response) => {
                setTokens(response.data);
                if (response.data.role === 'admin') {
                    navigate('/admin');
                } else {
                    navigate('/dashboard');
                }
            })
            .catch((error) => {
                alert('Login failed');
                console.error(error);
            });
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Sistem de gestionare a costumelor <br /> Magazie Smart Doina Timisului</h1>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <br />
            <br />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <br />
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};

export default Login;
